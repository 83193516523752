import React from 'react'
import { graphql } from "gatsby"
// components
import Layout from "../components/Layouts/layout"
import SEO from "../components/seo"
import {
    FirstScreen,
} from '../components/Projects'
import { FormOrder, Breadcrumbs } from '../components/Global'
import Pagination from "../ui-kit/Pagination"
// content
import YAMLGlobal from "../content/ru/global.yaml"

const ProjectsCategoryPage = ({ data, location, pageContext }) => {
    const projects = data.allDirectusOurProject.edges;
    const categories = data.allDirectusProjectsCategory.edges;

    const {humanPageNumber, numberOfPages, previousPagePath, nextPagePath, slug} = pageContext
    
    const list = [
      {
        link: "/",
        title: YAMLGlobal.breadCrumbs.main
      },
      {
        link: `projects/category/${slug}`,
        title: YAMLGlobal.breadCrumbs.projects
      }
    ]
    
    const projectsLocal = {
        langBase: "",
        YAMLGlobal,
    }
    
    return (
        <Layout local={projectsLocal} location={location}>
            <SEO title={`${data.seo.title} | Категория: ${projects[0].node.category.title} | Страница: ${humanPageNumber}/${numberOfPages}`} description={data.seo.description} />
            <Breadcrumbs list={list}/>
            <FirstScreen 
                categories={categories} 
                projects={projects}
                activeSlug={slug}
                Pagination={() => <Pagination
                    parentPath={`/projects/category/${slug}`}
                    nextPage={nextPagePath}
                    previousPage={previousPagePath}
                    countOfPages={numberOfPages}
                    currentPage={humanPageNumber}
                  />}
            />
            <FormOrder wave="white" />
        </Layout>
    )
}

export const query = graphql`
    query($skip: Int!, $limit: Int!, $slug: String!) {
        seo: directusProjectsSeo {
            title
            description
        }
        allDirectusOurProject(
            skip: $skip
            limit: $limit
            filter: {category: {slug: {eq: $slug}}}
            sort: {fields: sort}
        ) {
            edges {
                node {
                    category {
                        slug
                        title
                    },
                    id,
                    slug,
                    title,
                    title_photo {
                        localFile {
                            childImageSharp {
                                fluid {
                                    base64
                                    tracedSVG
                                    srcWebp
                                    srcSetWebp
                                    originalImg
                                    originalName
                                }
                            }
                        }
                    }
                }
            }
        },
        allDirectusProjectsCategory {
            edges {
                node {
                    id,
                    slug,
                    title
                }
            }
        }
    }
`

export default ProjectsCategoryPage;