import React, { useContext } from 'react'
import { Link} from 'gatsby'
import Img from 'gatsby-image'
import classNames from 'classnames'
// styles
import s from './FirstScreen.module.sass'
// ui-kit
import {
    Container,
    Title,
} from '../../../ui-kit'
// context
import {LayoutContext} from '../../Layouts/layout'

export default function FirstScreen({ categories = [], projects = [], activeSlug, Pagination}) {
    const {local: {langBase, YAMLGlobal}} = useContext(LayoutContext);
        
    return(
        <section className={s.firstScreen}>
            <Container>
                <Title type="subtitle" className={classNames("center", s.subtitle)}>utemsolar</Title>
                <Title>{YAMLGlobal.ourProjects}</Title>
                <div className={s.nav} aria-label="tab">
                    {
                        categories.map(({node}) => {
                            return (
                                <Link 
                                    key={node.id} 
                                    className={classNames(s.navItem, {
                                        [s.active]: activeSlug === node.slug
                                    })}
                                    to={`${langBase}/projects/category/${node.slug}`}
                                >{node.title || node.title_ua}</Link>
                            )
                        })
                    }
                </div>
                <div className={s.wrapper}>
                    {
                        projects.map(({node: {id, ...node}}) => {
                            return <Project key={id} node={node} img={node.title_photo.localFile?.childImageSharp.fluid} />
                        })
                    }
                </div>
                <Pagination/>
            </Container>
        </section>
    )
}

const Project = ({node, img, }) => {
    const {local: {langBase, YAMLGlobal}} = useContext(LayoutContext);

    return(
        <div className={s.project}>
            <Link to={`${langBase}/projects/${node.slug}`} className={s.projectLink}>
                <span className={s.more}>{YAMLGlobal.button.more}</span>
                <div className={s.img}>
                    <Img className={s.gatsbyImg} fluid={img}/>
                </div>
                <h4 className={s.title}>{node.title || node.title_ua}</h4>
            </Link>
        </div>
    )
}